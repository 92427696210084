import * as React from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"
import * as walletAdapterWallets from '@solana/wallet-adapter-wallets'
//import * as web3 from '@solana/web3.js';
require('@solana/wallet-adapter-react-ui/styles.css');

const WalletContextProvider = ({ children }) => {

    /*const endpoint = web3.clusterApiUrl('devnet');
    const wallets = [
        new walletAdapterWallets.PhantomWalletAdapter()
    ];*/

    //const endpoint = React.useMemo(() => web3.clusterApiUrl('devnet'), []);
    const endpoint = "https://mainnet.helius-rpc.com/?api-key=42a0fcbc-7cdd-41cb-ae2c-ea5fbc8f181f"
    const wallets = React.useMemo(() => [new walletAdapterWallets.PhantomWalletAdapter()], []);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default WalletContextProvider;