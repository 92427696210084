import React, { useRef, useEffect, useState } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';

const AccordionItem = ({ data, onToggle, active }) => {
  const { accordionItem, value } = data;
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (contentRef.currentscrollHeight < 80) {
      setHeight(active ? contentRef.current.scrollHeight : 0);
    } else {
      setHeight(active ? 80 : 0);
    }
  }, [active]);

  return (
    <li className='border w-7/12 rounded-lg mt-2 w-full'>
        <div
            className={`flex justify-between items-center cursor-pointer p-2 px-4 rounded-lg ${active ? 'rounded-b-none' : 'rounded-b-lg'} transition-all duration-300 ease-out hover:bg-[#1E1E1E] hover:bg-opacity-10`} 
            onClick={onToggle}>
            <h2 className="text-left select-none text-sm font-medium flex gap-1 items-center">
              {accordionItem} <span className='opacity-50 text-xs align-middle'>{`(${value?.length})`}</span>
            </h2>
            <div>
            {active ? <FaAngleDown className='h-3.5 w-3.5'/> : <FaAngleUp className='h-3.5 w-3.5'/>}
            </div>
        </div>
        <div
            ref={contentRef}
            style={{ maxHeight: `${height}px`, transition: 'max-height 0.3s ease-out' }}
            className='overflow-scroll'
        >
            <ul className='p-2 px-4'>
            {value &&
              value.map((item, index) => (
                <>
                    <li key={index} className='py-1 text-left flex flex-row justify-between text-sm font-medium opacity-80 gap-4'>
                      <p>{item?.rolename}</p>
                      <p className='italic text-right'>{item?.criteria}</p>
                    </li>
                </>
            ))}
            </ul>
        </div>
    </li>
  );
};

export default AccordionItem;
