import './App.css';
import WalletContextProvider from './contexts/WalletContextProvider';
import MediaQuery from 'react-responsive';

import Navbar from './components/navbar'
import Dashboard from './components/dashboard'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <WalletContextProvider>
        <MediaQuery minWidth={768}>
          <div>
            <Navbar />
            <Dashboard gridCount={3}/>
          </div>
          <Footer />
        </MediaQuery>

        <MediaQuery maxWidth={767}>
          <div>
            <Navbar />
            <Dashboard gridCount={8}/>
          </div>
          <Footer />
        </MediaQuery>
      </WalletContextProvider>
    </div>
  );
}

export default App;
