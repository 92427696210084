import * as React from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import bs58 from "bs58";
import { Toaster, toast } from 'sonner';

import UserCard from './UserCard'
import AddWalletButton from './Buttons/AddWalletButton'
import AddWalletModal from './Modals/AddWalletModal'

require('@solana/wallet-adapter-react-ui/styles.css');

const baseUrl = "https://das-mvp-api.vercel.app"

const Dashboard = ({ gridCount }) => {
    const [signedState, setSignedState] = React.useState(false)
    const [tryingToSign, setTryingToSign] = React.useState(false)
    const { connection } = useConnection();
    const { publicKey, signMessage } = useWallet();
    const [userInfo, setUserInfo] = React.useState({});

    const [isOpen, setIsOpen] = React.useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    React.useEffect(() => {
        if (!publicKey) {
            setUserInfo({})
            setSignedState(false)
            setTryingToSign(false)
            //toast.success('Logged out!')
        }

      }, [publicKey]);

    React.useEffect(() => {
        const handleSignIn = async () => {
            if (!signedState && !tryingToSign && connection && publicKey) {
                try {
                    setTryingToSign(true)

                    //Fetch the random code from the backend - prepare signature with code
                    const dataToSend = {
                        "walletaddress": publicKey
                    }

                    const getMessage = await fetch(`${baseUrl}/api/signature/message`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                              },
                            body: JSON.stringify(dataToSend),
                            credentials: 'include',
                          })
                    const fetchedMessage = await getMessage.json()
                
                    //Prepare signature
                    const encodedMessage = new TextEncoder().encode(fetchedMessage.message);
                    const signature = await signMessage(encodedMessage);

                    //Send signature to backend to verify
                    const sendSignature = await fetch(`${baseUrl}/api/signature/verify`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            },
                        body: JSON.stringify({
                            "walletaddress": publicKey.toString(),
                            "message": fetchedMessage.message,
                            "signature": bs58.encode(signature)
                        }),
                        credentials: 'include',
                        });
                    const response = await sendSignature.json();
                    if (response.userExists) {
                        setSignedState(true)

                        //Fetch user information
                        const getUserInformation = await fetch(`${baseUrl}/api/user/info`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                              },
                            credentials: 'include',
                          })
                        const information = await getUserInformation.json();
                        setUserInfo(information);
                        return(response.userExists)

                    } else if (!response.userExists && response.redirectUrl){
                        window.location.href = `${response.redirectUrl}?pubkey=${publicKey.toString()}`; // Redirect user to Discord OAuth
                    } else {
                        setSignedState(false)
                        toast.loading('Creating user!')
                    }
                } catch (error) {
                    setSignedState(false)
                    throw error
                }
            } else {
                setSignedState(false)
                setTryingToSign(false)
            }
        }
        if (!tryingToSign && publicKey && !signedState) {
            //handleSignIn()

            toast.promise(handleSignIn, {
            loading: 'Waiting for signature...',
            success: 'Successfully signed in!',
            error: 'Signature failed!',
            });
        }

    }, [connection, publicKey, signMessage, signedState, tryingToSign]);

    return (
        <main className='h-full text-[#1E1E1E] flex relative flex-wrap'>
            {isOpen && <AddWalletModal openModal={isOpen} closeModal={closeModal} />}
            <Toaster richColors/>
            <div className='flex float-left w-full h-fit'>
                <h2 className='text-3xl font-semibold'>
                    User Dashboard
                </h2>
            </div>

            <div className='relative flex flex-wrap justify-center items-center w-full'>
                <div className={`z-10 absolute mt-8 ${signedState && publicKey ? 'hidden': 'visible'}`}>
                    <p>Please connect your wallet to view the dashboard</p>
                </div>
                <div className={`z-0 w-full ${signedState && publicKey ? 'blur-none': 'blur-sm'}`}>
                    <ul id='userDashboard' className={`grid grid-cols-8 gap-3 h-max w-full`}>
                        <div className={`col-span-${gridCount} h-full`}>
                            <UserCard 
                                userInformation={userInfo}
                                button={<AddWalletButton disabled={signedState && publicKey ? true : false} onClick={openModal}/>}  
                            />
                        </div>
                    </ul>
                </div>
            </div>
        </main>
    );
};

export default Dashboard;
