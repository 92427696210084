import * as React from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
require('@solana/wallet-adapter-react-ui/styles.css');

const Navbar = () => {
    return (
        <>
            <nav className='mt-10 mb-2 flex flex-row justify-end'>
                <WalletMultiButton className='hover:!bg-[#9FC8EA] transition-all duration-200 !rounded-lg'/>
            </nav>
        </>
    );
};

export default Navbar;
