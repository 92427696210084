import React from "react";
import Accordion from "./Accordion/Accordion";
import Skeleton from "./Skeleton/Skeleton";

const UserCard = ({ userInformation, button }) => {
    return (
        <li id='userCard' className='border border-[#9FC8EA] border-opacity-30 rounded-lg flex flex-col justify-between h-max'>
            <div id='upperUserCard' className='flex flex-col mx-6 mt-6 text-left'>
                <div>
                    {userInformation?.user_info?.discordid ? (
                        <img
                            src={`https://cdn.discordapp.com/avatars/${userInformation.user_info.discordid}/${userInformation.user_info.avatar}.webp?size=240`}
                            className="w-28 h-28 rounded-lg"
                            alt="pfp"
                            draggable="false"
                        />
                    ) : (
                        <Skeleton width={28} height={28} radius="lg" />
                    )}
                </div>
                <h2 className="text-3xl font-extrabold mt-2">
                    {userInformation?.user_info?.username ? (
                        userInformation.user_info.username
                    ) : (
                        <Skeleton width="full" height="full" radius="lg" />
                    )}
                </h2>
                <p className="text-xs font-medium opacity-50">
                    {userInformation?.user_info?.created_at ? (
                        `Member since ${userInformation.user_info.created_at.split('T')[0]}`
                    ) : (
                        <Skeleton width="full" height="full" radius="lg" />
                    )}
                </p>
                <div className="w-8/12 my-8">
                    {userInformation?.wallets ? (
                        <Accordion
                            givenData={[
                                {
                                    accordionItem: "Linked Wallets",
                                    value: userInformation.wallets.map((wallet) => ({
                                        rolename: `${wallet.slice(0, 4)}...${wallet.slice(-4)}`,
                                    })),
                                },
                            ]}
                        />
                    ) : (
                        <Skeleton width="full" height="full" radius="lg" />
                    )}
                </div>
            </div>
            <div id='lowerUserCard' className='w-full flex flex-row justify-end gap-3 px-6 my-6'>
                {button}
            </div>
        </li>
    );
};

export default UserCard