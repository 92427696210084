import React from 'react';
import { PiWalletBold } from "react-icons/pi";

const AddWalletButton = ({ disabled, onClick }) => {
    return (
        <>
            <button 
                className={`py-1.5 px-7 rounded 
                bg-[#9FC8EA] 
                border border-[#9FC8EA] 
                text-white text-sm font-medium
                ${disabled ? 'cursor-pointer hover:bg-[#9FC8EA] hover:bg-opacity-10 hover:text-[#1E1E1E]' : 'cursor-not-allowed opacity-50'}
                flex flex-row items-center`}
                onClick={onClick}
                disabled={!disabled}
            >
                <PiWalletBold className='pr-2 w-6 h-6'/>
                Add Wallet
            </button>
        </>
    );
}

export default AddWalletButton;
