import React from 'react';
import AccordionItem from './AccordionItem';

const Accordion = ({ givenData }) => {
  const [clicked, setClicked] = React.useState(null);

  console.log(givenData)

  const handleToggle = (index) => {
    setClicked(clicked === index ? null : index);
  };

  return (
    <ul>
      {givenData.map((data, index) => (
        <AccordionItem
          onToggle={() => handleToggle(index)}
          active={clicked === index}
          key={index}
          data={data}
        />
      ))}
    </ul>
  );
};

export default Accordion;