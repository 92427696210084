import { useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { FiRefreshCw } from "react-icons/fi";

function AddWalletModal({ openModal, closeModal }) {
  const ref = useRef();

  useEffect(() => {
    if (openModal) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [openModal]);

  const handleDialogClick = (e) => {
    let rect = e.target.getBoundingClientRect();

    if (rect.left > e.clientX ||
        rect.right < e.clientX ||
        rect.top > e.clientY ||
        rect.bottom < e.clientY
    ) {
        closeModal()
    }
}

  return (
    <dialog
      className="mt-24 bg-[#FFFFFF] bg-opacity-100 backdrop-blur-none text-[#1E1E1E] w-half rounded-xl p-2"
      ref={ref}
      onCancel={closeModal}
      onClick={(e) => handleDialogClick(e)}
    >
        <div className='p-12 pt-8 pb-7'>
            <div className='flex flex-col justify-between items-start'>
                <div className='flex justify-end w-full'>
                    <button onClick={closeModal} className='items-end'>
                        <IoClose className='w-7 h-7'/>
                    </button>
                </div>
                <div className='pb-0.5 w-full flex flex-row justify-between items-start'>
                    <h2 className='text-3xl font-semibold'>
                        Add Wallets
                    </h2>
                </div>
                <p className="opacity-80">
                    To link a new wallet to your profile, please follow the steps below:
                </p>
            </div>
            <ul className='pt-5 pb-5 p-5 mt-5 mb-8 flex flex-col justify-between border border-[#9FC8EA] border-opacity-30 rounded-xl'>
                <li className='flex justify-between'>
                    <p className='font-semibold text-left pr-16'>Step 1. </p>
                    <p className='text-[#1E1E1E] italic font-medium text-right'>
                        In your browser extension, change to your desired wallet.
                    </p>
                </li>
                <li className='flex justify-between'>
                    <p className='font-semibold text-left'>Step 2. </p>
                    <p className='text-[#1E1E1E] italic font-medium text-right'>
                        Refresh this page.
                    </p>
                </li>
                <li className='flex justify-between'>
                    <p className='font-semibold text-left'>Step 3. </p>
                    <p className='text-[#1E1E1E] italic font-medium text-right'>
                        Reconnect your wallet and redo the verification.
                    </p>
                </li>
                <li className='flex justify-between mt-4'>
                    <p className='font-semibold text-left'>Questions? </p>
                    <p className='text-[#1E1E1E] italic font-medium text-right'>
                        Feel free to reach out using the links in the footer!
                    </p>
                </li>
            </ul>
            <div className='flex flex-row justify-center'>
                <button 
                    className='
                    py-1.5 px-7 rounded 
                    bg-[#9FC8EA] hover:bg-[#F5F9F9] hover:bg-opacity-0
                    border border-[#9FC8EA] 
                    text-white text-sm font-medium hover:text-[#1E1E1E]
                    cursor-pointer
                    flex flex-row items-center'
                    onClick={() => {window.location.href = '/'}}
                    >
                        <FiRefreshCw className='pr-2 w-6 h-6'/>
                        Refresh
                </button>
            </div>
        </div>
    </dialog>
  );
}

export default AddWalletModal;