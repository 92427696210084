import React from 'react';
import { ReactComponent as TwitterIcon } from '../img/twitter_icon.svg'
import { ReactComponent as DiscordIcon } from '../img/discord_icon.svg'
import { ReactComponent as HobotLogo } from '../img/Logo_Hobot.svg'

const Footer = () => {
    return (
        <footer className='my-10 flex flex-col justify-end justify-items-end opacity-50'>
            <hr className='w-full border-[#9FC8EA] border-solid border-0.5 opacity-50'/>
            <div className='flex justify-between mt-3'>
                <p className='text-xs text-[#9FC8EA] flex flex-row items-center'>
                    Powered by <HobotLogo fill='#9FC8EA' className='w-4 h-4 mr-2 ml-2 cursor-text'/> Bobois Clubhouse
                </p>
                <div className='flex justify-between'>
                    <a
                    className='mr-1' 
                    href="https://x.com/im_big_bob"
                    >
                        <TwitterIcon className='w-4 h-4' fill='#9FC8EA'/>
                    </a>
                    <a 
                    className=''
                    href="https://discord.com/users/473203136190742548"
                    >
                        <DiscordIcon className='w-4 h-4' fill='#9FC8EA'/>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;